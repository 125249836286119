var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "meeting-card",
      attrs: { flat: "", dark: "", height: "50" },
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "" } },
        [
          _c("StarRating", {
            staticClass: "vote-element-star-rating",
            attrs: {
              "read-only": false,
              increment: 0.5,
              padding: 2,
              "star-size": 25,
              "show-rating": false,
              "border-width": 0,
              "inactive-color": "#FFF",
              "active-color": "#6E69D1",
              "border-color": "transparent",
            },
            on: {
              "rating-selected": function ($event) {
                return _vm.rated($event)
              },
            },
          }),
          _c(
            "span",
            {
              staticClass: "average-rating",
              staticStyle: { position: "absolute", right: "40%" },
              on: { click: _vm.reset },
            },
            [_vm._v(" " + _vm._s(_vm.average) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }