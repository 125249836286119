<template>
  <v-card flat class="meeting-card" dark height="50">
    <v-layout row justify-center>
      <StarRating
        class="vote-element-star-rating"
        :read-only="false"
        :increment="0.5"
        :padding="2"
        :star-size="25"
        :show-rating="false"
        :border-width="0"
        inactive-color="#FFF"
        active-color="#6E69D1"
        :border-color="'transparent'"
        @rating-selected="rated($event)"
      />
      <span
        style="position: absolute; right: 40%"
        @click="reset"
        class="average-rating"
      >
        {{ average }}
      </span>
    </v-layout>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import StarRating from "vue-star-rating"
import * as moment from "moment"

const RtbCard = () => import("@/components/GroupTeams/Common/Games/RtbCard.vue")

export default {
  name: "MeetingRating",
  components: {
    RtbCard,
    StarRating
  },
  methods: {
    rated(rate) {
      const play = this.meetingPlays.filter(
        play => play.userID === this.user.id
      )
      //   console.log("MISSION PLAYS", this.missionPlays)
      //   console.log("PLAY", play)
      var obj = {}
      if (play[0]) obj.id = play[0].id
      var sTime = new Date()
      obj.teamID = 0
      obj.gameID = this.game.id
      obj.userID = this.user.id
      obj.missionID = "meeting-rating"
      obj.mission = "Meeting Rating"
      obj.points = 0
      obj.rating = rate
      obj.score = 0
      obj.time = moment(sTime).unix()
      obj.show = false
      console.log("OBJ", obj)
      //console.log("NUMB OR TEAM PLAYERS SLEECT", this.missionPlaysArray)
      this.$store.dispatch("addPlay", obj)
    },
    reset() {
      const plays = this.meetingPlays
      for (var i in plays) {
        // console.log("PLAY", this.meetingPlays[i])
        this.$store.dispatch("deletePlay", plays[i].id)
      }
    }
  },
  computed: {
    ...mapGetters(["game", "getCurrentMission"]),
    ...mapGetters("auth", ["user", "isHost", "isAudit", "clientID"]),
    ...mapGetters(["teamID", "team", "game", "gameID", "orgID", "plays"]),
    average() {
      if (this.meetingPlays.length === 0) return
      var total = 0
      var count = 0
      for (var i in this.meetingPlays) {
        total = total + this.meetingPlays[i].rating
        count++
      }
      var avg = total / count
      avg = Math.round(100 * avg) / 100
      // console.log("TOTAL", Math.round(10*total)/10)
      return avg
    },
    meetingPlays() {
      var plays = Object.values(this.plays)
      return plays.filter(play => play.missionID === "meeting-rating")
    },
    announcement() {
      if (this.game.meetingAnnouncement.length > 1) {
        return this.game.meetingAnnouncement
      } else if (this.getCurrentMission.meetingText) {
        return this.getCurrentMission.meetingText
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss">
.meeting-card {
  background: transparent;
  height: 60px;
  margin: -5px 0 5px;
}
.theme--dark.v-card {
  background: transparent;
}
.average-rating {
  font-size: 30px;
  color: #fff;
  margin-left: 20px;
  margin-top: -7px;
  font-family: "Right Grotesk";
}
.meeting-announcement-card {
  position: relative;
  margin: -10px auto 10px;
  width: fit-content;
  height: auto;
  overflow: scroll;
  max-height: 120px;
  max-width: 50%;
  min-width: 30%;
  padding: 5px !important;
  font-size: 21px;
  font-weight: bolder;
  overflow-wrap: break-word;
  overflow-y: auto !important;
  a {
    color: $primary-accent-color;
  }
  background-color: none;
}
</style>
